<template>
  <div class="core-values">
    <ul>
      <li v-for="item in data">
        <img :src="require(`@/assets/images/${item.img}.png`)" alt="" />
        <h3>{{ item.title }}</h3>
        <p v-html="item.content"></p>
      </li>
    </ul>
  </div>
</template>

<script>
import { coreValues } from "./data/index";
export default {
  data() {
    return {
      data: [],
    };
  },
  created() {
    this.data = coreValues;
  },
};
</script>

<style lang="scss" scoped>
.core-values {
  ul {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    justify-content: space-between;
    margin: 0;
  }
  li {
    width: 384px;
    height: 287px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #d8d8d8;
    padding: 30px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 24px;
    h3 {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      margin-top: 15px;
      margin-bottom: 32px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 32px;
        height: 2px;
        background: #fbb726;
        border-radius: 10px;
        bottom: -12px;
        left: 50%;
        margin-left: -16px;
      }
    }
    p {
      margin: 0;
      font-weight: 400;
      color: #666666;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>