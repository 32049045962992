const coreValues = [
    {
        img:"synergy",
        title:"质控提质增效",
        content:"转变医院病历质控以手工为主的工作模式<br/>为医院带来智能化信息工具支撑<br/>提高工作质量，效率，降低成本"
    },
    {
        img:"protest",
        title:"避免医保拒付",
        content:"提高病案数据质量，避免医保、商保拒<br/>付为医院和患者带来的损失。"
    },
    {
        img:"available",
        title:"大数据可利用",
        content:"解决医院科研，教学数据支撑不足的问题<br/>为电子病历数据应用过级提供良好数据基础<br/>实现重大传染病及时预警"
    },
    {
        img:"dispute",
        title:"避免法律纠纷",
        content:"提高数据客观性，真实性，准确性，合规性<br/>避免因工作差错带来的法律纠纷"
    },
    {
        img:"contradiction",
        title:"解决人资矛盾",
        content:"解决医院质控工作人力不足，人员临床经验<br/>匮乏的现实问题<br/>解决日常工作量大，难以完全覆盖，工作质<br/>量难以保障的问题"
    },
    {
        img:"examine",
        title:"支撑绩效考核",
        content:"提高病案质量，为医院内部绩效考核提供数据支撑<br/>提高病案质量提高医院整体绩效表现"
    }
]

const coreTechnology = [
    {
        img:"structural",
        title:"病历结构化",
        content:"机器翻译病历结构化<br/>迁移学习+小样本学习<br/>无监督学习"
    },
    {
        img:"knowledge",
        title:"医学知识图谱",
        content:"起点：症候群<br/>超边：边的非线性权重"
    },
    {
        img:"clinical",
        title:"临床导航",
        content:"深度强化学习<br/>生成对抗学习"
    }
]





export {
    coreValues,
    coreTechnology
}